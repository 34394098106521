import React, { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Breadcrumbs } from '@mui/material';

const SiraBreadcrumbs = () => {
  const location = useLocation();
  const { pathname } = location;
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

  useEffect(() => {
    setBreadcrumbs(pathname.split('/'));
  }, [pathname]);

  const classes = {
    breadcrumbLink: {
      textTransform: 'capitalize' as any,
      color: 'black',
    },
  };

  return (
    <Box ml={2} mt={1.5} height={50}>
      <Breadcrumbs aria-label="breadcrumb">
        {pathname === '/' ? (
          <Typography variant="body2" sx={classes.breadcrumbLink}>
            Home
          </Typography>
        ) : (
          breadcrumbs.map((segment, segmentIndex) => {
            // Reconstruct the url only up to the current segment's index
            let breadcrumbUrl = breadcrumbs
              .filter((_, i) => i <= segmentIndex)
              .join('/');
              if (segmentIndex === 1 && segment === 'owner'){
                 breadcrumbUrl = '/';
              };

            // Redirect to /accountOwner if the segment is 'account'
            if (segment === 'account' && segmentIndex > 1) {
              breadcrumbUrl = breadcrumbs
                .filter((_, i) => i <= segmentIndex - 1)
                .join('/');
            }

            return segmentIndex !== breadcrumbs.length - 1 ? (
              <RouterLink key={`breadcrumb_${segment}`} to={breadcrumbUrl}>
                <Typography sx={classes.breadcrumbLink} variant="body2">
                  {segmentIndex === 0 ? 'Home' : segment}
                </Typography>
              </RouterLink>
            ) : (
              <Typography
                key={`breadcrumb_${segment}`}
                variant="body2"
                sx={classes.breadcrumbLink}
              >
                {segmentIndex === 0 ? 'Home' : segment}
              </Typography>
            );
          })
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default SiraBreadcrumbs;
