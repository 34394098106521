import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { useTheme, Box, Grid, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import { roundNumber } from '../../../utils/App.utils';
import moneyFormatter from '../../../moneyFormatter';
import SimplePieChart from '../../SimplePieChart';
import { InvestmentRate } from '../../../api/InvestmentRateApi.d';
import { useGlobalContext } from '../../../auth/useGlobalContext';
import { ro } from 'date-fns/locale';

export interface AllocationPieChartProps {
  investmentRates: Array<InvestmentRate>;
  amount: string | number;
  percent: string | number;
  asPercent?: boolean;
  feeAmount?: string | number;
}

function AllocationPieDisplay({ investmentRates, asPercent = false, amount, feeAmount = null }) {
  const [allocTotal, setAllocTotal] = React.useState(0);
  const [penaltyTotal, setPenaltyTotal] = React.useState(0);
  const currencyOrPercentTotalAmount = asPercent ? 100 : amount;
  const [pieData, setPieData] = React.useState([
    { name: 'Unallocated', value: currencyOrPercentTotalAmount },
  ]);
  const theme = useTheme();
  const { organization } = useGlobalContext();
  const { values } = useFormikContext();

  const [debouncedValues] = useDebounce(values, 600);

  const classes = {
    error: {
      color: theme.palette.error.main,
    },
    success: {},
  };

  React.useEffect(() => {
    const allocs = getIn(values, 'allocations');

    if (!allocs) return;
    0
    const total = allocs.reduce((accum, current) => {
      const amountOrPercent = asPercent ? current.percent : current.amount;
      return roundNumber(accum) + roundNumber(amountOrPercent);
    }, 0);

    // grab the penalty fee amount from the formik values and calculate the remaining balance
    const penalty = allocs.reduce((accum, current) => {
      const penaltyAmount = current.penaltyAmount;
      return roundNumber(accum) + roundNumber(penaltyAmount);
    }, 0);


    const newPieData = allocs.reduce(
      (accum, current, index) => {
        const amountOrPercent = asPercent ? current.percent : current.amount;
        if (roundNumber(amountOrPercent) > 0) {
          accum.push({
            name: investmentRates[index].description,
            value: roundNumber(amountOrPercent),
          });
        }
        return accum;
      },
      [
        {
          name: 'Not Allocated',
          value: roundNumber(currencyOrPercentTotalAmount) - total,
        },
      ]
    );
    setPieData(newPieData);
    setAllocTotal(total);
    setPenaltyTotal(penalty);
  }, [debouncedValues]);

  const remainingBalance =
    roundNumber(currencyOrPercentTotalAmount) - allocTotal;

  const penaltyRemainingBalance = roundNumber(feeAmount) - penaltyTotal;

  return (
    <Box>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Typography variant="subtitle1">Total Amount is </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {asPercent ? (
                  <NumberFormat
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={organization.investmentRatePrecision}
                    isNumericString
                    value={currencyOrPercentTotalAmount}
                    suffix="%"
                  />
                ) : (
                  moneyFormatter.format(currencyOrPercentTotalAmount)
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Amount Remaining</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={remainingBalance !== 0 ? classes.error : classes.success}
              >
                {asPercent ? (
                  <NumberFormat
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={organization.investmentRatePrecision}
                    isNumericString
                    value={remainingBalance}
                    suffix="%"
                  />
                ) : (
                  moneyFormatter.format(remainingBalance)
                )}
              </Typography>
            </Grid>
            {feeAmount > 0 ? <>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>Financial Organization Fee Amount</Typography>
              </Grid>
              <Box sx={{paddingTop: '10px', paddingLeft: '25px'}}>
                <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  sx={ {...classes.success, marginRight: '10px'}}
                >
                  Total
                </Typography>
                <Typography
                  variant="h6"
                  sx={ {...classes.success, marginRight: '20px'}}
                >
                  {moneyFormatter.format(feeAmount)}
                </Typography>

                <Typography
                  variant="h6"
                  sx={penaltyRemainingBalance !== 0 ? {...classes.error,marginRight: '10px'} : {...classes.success, marginRight: '10px'}}
                >
                  Remaining
                </Typography>
                <Typography
                  variant="h6"
                  sx={penaltyRemainingBalance !== 0 ? classes.error : classes.success}
                >
                  {moneyFormatter.format(penaltyRemainingBalance)}
                </Typography>
                </Box>
              </Box>

            </> : null}
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <SimplePieChart data={pieData} unit={asPercent ? '%' : '$'} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AllocationPieDisplay;
