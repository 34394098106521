import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Divider,
  Box,
  Button,
  Paper,
  Popover,
  IconButton,
  Card,
  CardContent,
  Avatar,
  CardActions,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import AccountNewIcon from '../../icons/AccountNew';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import { getAccountOwnerAccounts } from '../../api/AccountOwnerApi';
import {
  AccountOwnerAccountsResponse,
  AccountOwnerAccount,
  AccountOwner,
} from '../../api/AccountOwnerApi.d';
import AccountOwnerAccountSummaryCard from './AccountOwnerAccountSummaryCard';
import AccountOwnerAccountDetails from './AccountOwnerAccountDetails';
import {
  AccountStatus,
  TransactionType,
  Account,
} from '../../api/AccountApi.d';
import TransactionSignatureIcon from '../../icons/TransactionSignature';
import TransactionPendingIcon from '../../icons/TransactionPending';
import TransactionReviewIcon from '../../icons/TransactionReview';

import { hasPermission, linkToTransaction, Permissions } from '../../utils/transaction.utils';
import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { UserRole } from '../../api/UserApi.d';
import { getWorkflowPermissions } from '../../api/OrganizationApi';

interface AccountOwnerAccountsProps {
  accountOwner: AccountOwner;
  accountIdToSelect?: string; // Account ID to preselect once they're all fetched
}

function AccountOwnerAccounts(props: AccountOwnerAccountsProps) {
  let isMounted = true;
  const { accountOwner = {}, accountIdToSelect = '' } = props;
  const { accountOwnerId = '' } = accountOwner;
  const { user } = useUser();
  const navigate = useNavigate();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [response, setResponse] = useState({} as AccountOwnerAccountsResponse);
  const [selectedAccount, setSelectedAccount] = useState(
    {} as AccountOwnerAccount
  );
  const [workflowPermissions, setWorkflowPermissions] = useState<any>([]);

  const { data: accounts = [] } = response;
  const wipAccounts = accounts.filter(
    ({ accountStatus }) =>
      [AccountStatus.pending, AccountStatus.signature].includes(
        accountStatus
      ) ||
      (AccountStatus.review === accountStatus &&
        user.roles.includes(UserRole.orgTransactionsAdmin))
  );
  const theme = useTheme();
  const classes = {
    accountLink: {
      justifyContent: 'flex-start' as any,
      width: '100%',
      textDecoration: 'none' as any,
      textTransform: 'none' as any,
    },
    cardContent: {
      padding: '16px 0',
    },
    avaDot: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(9),
      height: theme.spacing(9),
      margin: 'auto',
      '& svg': {
        color: theme.palette.secondary.light,
      },
    },
    center: {
      justifyContent: 'center',
    },
    root: {
      cursor: 'pointer',
      padding: '0',
      '& button': {
        alignItems: 'flex-start',
        textTransform: 'none',
      },
    },
  };

  // Link to the WIP transaction based on
  function goToTransaction(row: Account) {
    linkToTransaction(row.accountOwnerId, row.accountId, row, navigate);
  }

  // Get all of the accounts for the current account owner
  async function fetchAccountOwnerAccounts(): Promise<void> {
    await getAccountOwnerAccounts(
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then((res) => {
        if (isMounted) {
          setResponse(res);
        }
      })
      .catch(() => {
        if (isMounted) {
          addGlobalMessage("Error fetching account owner's accounts");
        }
      });
  }

  const columns: GridColDef[] = [
    {
      field: 'accountStatus',
      headerName: ' ',
      width: 0,
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const statusIcons = {
          [AccountStatus.open]: <AssignmentTurnedInIcon />,
          [AccountStatus.closed]: <BlockIcon />,
          [AccountStatus.pending]: <TransactionPendingIcon />,
          [AccountStatus.signature]: <TransactionSignatureIcon />,
          [AccountStatus.review]: <TransactionReviewIcon />,
        };
        const [rolesAnchorEl, setRolesAnchorEl] =
          useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setRolesAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setRolesAnchorEl(null);
        };

        return (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <IconButton
                color="primary"
                onClick={handleClick}
                aria-label={`account status ${value}`}
                size="large"
              >
                {statusIcons[value.toString()]}
              </IconButton>
            </Grid>
            <Popover
              elevation={3}
              open={Boolean(rolesAnchorEl)}
              onClose={handleClose}
              anchorEl={rolesAnchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography variant="body2">Status: {value.toString()}</Typography>
              </Box>
            </Popover>
          </>
        );
      },
    },
    {
      field: 'accountType',
      headerName: 'Type',
      width: 300,
      renderCell: (params: GridCellParams) => {
        const { row = {}, value = '' } = params;
        const typeId = value.toString();

        return (
          <Button
            onClick={() => {
              goToTransaction(row);
            }}
            sx={classes.accountLink}
          >
            <Box overflow="hidden" textOverflow="ellipsis">
              {getAppConstant(ConstantsMappingKey.accountType, typeId)}
            </Box>
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (user.token && user.organizationId && accountOwnerId) {
      fetchAccountOwnerAccounts();
    }

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId, accountOwnerId]);

  // Once a response comes back, preselect the account by ID passed
  useEffect(() => {
    const accountToSelect = accounts.find(
      ({ accountId = '' }) => Number(accountId) === Number(accountIdToSelect)
    );

    if (accountToSelect) {
      setSelectedAccount(accountToSelect);
    }
  }, [accounts]);

  const retrieveWorkflowPermissions = async () => {

     await getWorkflowPermissions(user.organizationId, user.token,user).then((res) => {
      // created an array  of all workflowpermissions coming back from the api
      const permissions = res.data.map((permission) => {
        return permission.workflowPermission;
      }
      );

      setWorkflowPermissions(permissions);
    }
    ).catch((err) => {
      addGlobalMessage('Error fetching allowed workflow transactions'
      );
    });
  }



  useEffect(() => {
    if (user.organizationId &&
      user.token) {
      retrieveWorkflowPermissions();
    }
  }, [user.organizationId,
  user.token,]);

  return (
    <>
      {Boolean(wipAccounts.length) && (
        <Box mt={4}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Pending Accounts
          </Typography>

          <Divider />

          <Paper>
            <Box p={2} mt={2}>
              <DataGrid
                components={{
                  NoRowsOverlay: SiraNoRowsOverlay,
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10]}
                disableColumnMenu
                hideFooter
                autoHeight
                columns={columns}
                rows={wipAccounts.map((result, id) => ({
                  id,
                  transactionType: TransactionType.account,
                  ...result,
                }))}
              />
            </Box>
          </Paper>
        </Box>
      )}

      <Box mt={4}>
        <Typography variant="h5" color="secondary" gutterBottom>
          Accounts
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {accounts
            // Sort account cards by ID so they don't jump around when editing
            .sort(({ accountId: a }, { accountId: b }) => (a > b ? 1 : -1))
            .map((account) => (
              ![AccountStatus.pending, AccountStatus.signature, AccountStatus.review].includes(account.accountStatus) && <Grid
                item
                xs={6}
                md={4}
                key={`account-summary-card_${account.accountId}`}
              >
                <AccountOwnerAccountSummaryCard
                  account={account}
                  selectedAccountId={selectedAccount.accountId}
                  setSelectedAccount={setSelectedAccount}
                />
              </Grid>
            ))}
          <Grid item xs={6} md={4}>
            {hasPermission(Permissions.ACCOUNTS, workflowPermissions) && (<Card sx={classes.root} elevation={7}>
              <Button
                data-qa="open-new-account"
                fullWidth
                size="medium"
                color="secondary"
                onClick={() => {
                  navigate(`/transactions/newAccount?accountOwnerId=${accountOwner.accountOwnerId}`);
                }}
              >
                <CardContent sx={{ padding: '0 0' }}>
                  <Avatar sx={classes.avaDot}>
                    <AccountNewIcon
                      style={{ fontSize: 42 }}
                    />
                  </Avatar>
                  <CardActions sx={classes.center}>Open New Account</CardActions>
                </CardContent>
              </Button>
            </Card>)}
          </Grid>
        </Grid>

        {selectedAccount.accountId && (
          <AccountOwnerAccountDetails
            onAccountUpdate={(data) => {
              fetchAccountOwnerAccounts();
              setSelectedAccount(data);
            }}
            selectedAccount={selectedAccount}
            accountOwner={accountOwner}
            onAccountDelete={() => {
              fetchAccountOwnerAccounts();
            }}
          />
        )}
      </Box>
    </>
  );
}

export default AccountOwnerAccounts;
