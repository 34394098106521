import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useGlobalContext } from '../../auth/useGlobalContext';
import { useUser } from '../../auth/useUser';
import {
    getWorkflowPermissions,
    updateOrg,
    updateSupportedAccountTypes,
    updateWorkflowPermissions,
} from '../../api/OrganizationApi';
import OrgOptionsForm, {
    ORG_OPTIONS_INIT,
} from '../form/organization/OrgOptionsForm';
import { errorMessages } from '../../utils/errorhandling.utils';
import OrgWorkflowsForm, { ORG_WORKFLOWS_TRANSACTION_INIT } from '../form/organization/OrgWorkflowsForm';

function OrganizationWorkflowPermissions() {
    let isMounted = true;
    const { organization, setCurrentOrg, addGlobalMessage } = useGlobalContext();
    const { user } = useUser();
    const [workflowPermissions, setWorkflowPermissions] = useState<any>({});
    // Separate endpoint to update the supported account types


    const saveWorkflowPermissions = async (data) => {

        //rebuild data to create an array with individual workflow permissions
       const mergedData = data.workflowPermission.map((permission) => {
            return {
                financialOrganizationId: user.organizationId,
                workflowPermission: permission
            }
        });

        // Update shallow org details
        await updateWorkflowPermissions(mergedData, user.organizationId, user.token, user)
            .then((res) => {
                if (isMounted) {
                    const permissions = res.data.map((permission) => {
                        return permission.workflowPermission;
                    }
                    );
    
                    setWorkflowPermissions({workflowPermission: permissions});               
                
                    addGlobalMessage('Workflow permissions saved successfully', {
                        severity: 'success',
                    });
                
                }
            })
            .catch((err) => {
                if (isMounted) {
                    addGlobalMessage(
                        errorMessages(err) || 'Error saving organization information'
                    );
                }
            });
    };

    const retrieveWorkflowPermissions = async () => {

         await getWorkflowPermissions(user.organizationId, user.token,user).then((res) => {
            if (isMounted) {
                // created an array  of all workflowpermissions coming back from the api
                const permissions = res.data.map((permission) => {
                    return permission.workflowPermission;
                }
                );

                setWorkflowPermissions({workflowPermission: permissions});
            }
        }
        ).catch((err) => {
            if (isMounted) {
                addGlobalMessage(
                    errorMessages(err) || 'Error fetching supported account types'
                );
            }
        });
    }


    useEffect(() => {
        if (user.token && user.organizationId) {
            retrieveWorkflowPermissions();
        }
        return () => {
            isMounted = false;
        };
    }, [user.token, user.organizationId]);

    return (
        <Box width="1">
            <OrgWorkflowsForm
                initialValues={{...ORG_WORKFLOWS_TRANSACTION_INIT, ...workflowPermissions}}
                onSubmit={saveWorkflowPermissions}
            />
        </Box>
    );
}

export default memo(OrganizationWorkflowPermissions);
