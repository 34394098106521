import { Grid, Typography, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';

import { RecurringDistribution } from '../../api/RecurringDistributionApi.d';
import { FREQUENCY_TEXT } from '../form/distribution/DistributionSchedule';
import { Frequency } from '../form/distribution/Frequency';
import { useGlobalContext } from '../../auth/useGlobalContext';
import { ConstantsMappingKey } from '../../api/SharedTextApi.d';
import { distributionMethodShortNames } from '../../app.constants';
import EditRecurringDistribution from './EditRecurringDistribution';
import { useUser } from '../../auth/useUser';
import { UserRole } from '../../api/UserApi.d';
import { SiraDeleteModal } from '../SiraDeleteModal';
import { deleteRecurringDistribution } from '../../api/RecurringDistributionApi';
import { DistributionStatus } from '../../api/DistributionApi.d';
import { errorMessages } from '../../utils/errorhandling.utils';

interface RecurringDistributionsDisplayProps {
  recurringDistribution?: RecurringDistribution;
  onExit: Function;
  accountOwnerId: string;
  accountId: string;
  onEdit: Function;
}

function OwnerResponsibleTypeDisplay() {
  return (
    <>
      <Typography gutterBottom>Owner Responsible</Typography>
      <Typography gutterBottom>
        Do not schedule distributions to satisfy the account owner&apos;s RMD
        each year
      </Typography>
      <Typography variant="body2">
        The account owner must still receive the RMD for this IRA but will be
        responsible for withdrawing the RMD every year instead of having the
        financial organization automatically distribute the amount.
      </Typography>
    </>
  );
}

function RecurringDistributionDisplay({
  recurringDistribution,
  onExit,
  accountOwnerId,
  accountId,
  onEdit,
}: RecurringDistributionsDisplayProps) {
  const {
    additionalStateWithholding,
    lifeExpectancyTable,
    distributionMethod,
    distributionReason,
    federalWithholdingPercent,
    frequency,
    recurringDistributionId,
    signedDate,
    startDate,
    endDate,
    stateWithholdingPercent,
    term,
    toAccountNumber,
    toAccountType,
    toAccountTypeName,
    toFinancialOrganization,
    totalAmount,
    ownerResponsible,
    createdByDefault,
    distributionAmountType,
    distributionStatus,
    activeDate,
  } = recurringDistribution;
  let isMounted = true;
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  const [isEditing, setisEditing] = useState(false);
  const { user } = useUser();
  const [userRole, setUserRole] = useState(false as boolean);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [modalData, setModalData] = useState({ body: '', title: '' });

  const buildData = () => {
    setModalData({
      title: 'Recurring Distribution',
      body: `Do you want to delete this recurring  distribution?`,
    });
    setmodalOpen(true);
  };

  const handleClose = () => {
    setmodalOpen(false);
  };

  // Delete the selected distribution record (superuser permission)
  const deleteSelectedDistribution = async (): Promise<void> => {
    setIsDeleting(true);

    await deleteRecurringDistribution(
      recurringDistributionId,
      accountId,
      accountOwnerId,
      user.organizationId,
      user.token,
      user
    )
      .then(() => {
        onExit();
        addGlobalMessage('Recurring Distribution successfully deleted', {
          severity: 'success',
        });
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not delete this recurring distribution'
        );
      });

    if (isMounted) {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (user.organizationId && user.token) {
      setUserRole(user.roles.includes(UserRole.orgTransactionsAdmin));
    }

    return () => {
      isMounted = false;
    };
  }, [user.organizationId, user.token, accountId]);

  return (
    <Grid container spacing={3}>
      {!isEditing ? (
        <>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Start Date</Typography>
            <Typography variant="body1">
              {startDate && format(parseISO(String(startDate)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
          {endDate && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6">End Date</Typography>
              <Typography variant="body1">
                {endDate && format(parseISO(String(endDate)), 'MM/dd/yyyy')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Date Signed</Typography>
            <Typography variant="body1">
              {signedDate && format(parseISO(String(signedDate)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Type</Typography>
            {ownerResponsible ? (
              <OwnerResponsibleTypeDisplay />
            ) : (
              <Typography variant="body1">{distributionAmountType}</Typography>
            )}
          </Grid>

          {!ownerResponsible && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Term</Typography>
                <Typography variant="body1">
                  {term || lifeExpectancyTable}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Frequency</Typography>
                <Typography variant="body1">
                  {FREQUENCY_TEXT[frequency as unknown as Frequency]}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Reason</Typography>
                <Typography variant="body1">
                  {getAppConstant(
                    ConstantsMappingKey.distributionReason,
                    distributionReason
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Method</Typography>
                <Typography variant="body1">
                  {distributionMethodShortNames[distributionMethod] ||
                    distributionMethod}
                </Typography>
                {toFinancialOrganization && (
                  <Typography variant="body1">
                    To: {toFinancialOrganization}
                  </Typography>
                )}
                {toAccountNumber && (
                  <Typography variant="body1"># {toAccountNumber}</Typography>
                )}
                {toAccountType && (
                  <Typography variant="body1">{toAccountType}</Typography>
                )}
                {toAccountTypeName && (
                  <Typography variant="body1">
                    To: {toAccountTypeName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">ID</Typography>
                <Typography variant="body1">
                  {recurringDistributionId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Total Amount</Typography>
                <Typography variant="body1">
                  <NumberFormat
                    value={totalAmount}
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    isNumericString
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
            <Typography variant="h6">Active Date</Typography>
            <Typography variant="body1">
              {activeDate && format(parseISO(String(activeDate)), 'MM/dd/yyyy')}
            </Typography>
          </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Federal Withholding</Typography>
                <Typography variant="body1">
                  <NumberFormat
                    value={federalWithholdingPercent}
                    suffix="%"
                    displayType="text"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">State Withholding</Typography>
                <Typography variant="body1">
                  <NumberFormat
                    value={stateWithholdingPercent}
                    suffix="%"
                    displayType="text"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Additional State Withholding
                </Typography>
                <Typography variant="body1">
                  <NumberFormat
                    value={additionalStateWithholding}
                    prefix="$"
                    displayType="text"
                    thousandSeparator
                    isNumericString
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Grid>{' '}
            </>
          )}
          <Grid item xs={12} />
          <Grid container spacing={0.5} mt={3}>
            <Grid item>
              <Box pt={1} ml={2}>
                {createdByDefault ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Box>
            </Grid>{' '}
            <Grid item xs={11}>
              <Typography variant="h6" color="primary" pt={1}>
                Created By Default
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box pl={2} mt={3}>
                <Button
                  aria-label="Go back to account distributions"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onExit();
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  All Distributions
                </Button>
                {userRole && (
                  <Button
                    aria-label="Edit distribution details"
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setisEditing(true);
                    }}
                    sx={{ ml: 2 }}
                  >
                    Edit Details
                  </Button>
                )}
                {ownerResponsible ||
                distributionStatus === DistributionStatus.inActive ? (
                  <Button
                    disabled={isDeleting}
                    aria-label="Delete this recurring  distribution"
                    variant="contained"
                    color="error"
                    onClick={buildData}
                    startIcon={<DeleteIcon />}
                    sx={{ ml: 2 }}
                  >
                    Delete Distribution
                  </Button>
                ) : null}
              </Box>
            </Grid>
            <SiraDeleteModal
              title={modalData.title}
              body={modalData.body}
              showPrompt={modalOpen}
              handleClose={handleClose}
              deleteTransaction={deleteSelectedDistribution}
            />
          </Grid>
        </>
      ) : (
        <>
          <EditRecurringDistribution
            editRecurringDistribution={recurringDistribution}
            accountOwnerId={accountOwnerId}
            accountId={accountId}
            ownerResponsible={ownerResponsible}
            cancel={() => {
              setisEditing(false);
            }}
            isEditing={(id) => {
              onEdit(id);
              onExit();
            }}
          />
        </>
      )}
    </Grid>
  );
}

export default RecurringDistributionDisplay;
