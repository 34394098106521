import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

import Layout from '../components/Layout';
import ActionCard from '../components/ActionCard';
import PaperlessPage from '../components/PaperlessPage';
import BeneficiariesEditIcon from '../icons/BeneficiariesEdit';
import AddMoneyIcon from '../icons/AddMoney';
import AccountNewIcon from '../icons/AccountNew';
import RemoveMoneyIcon from '../icons/RemoveMoney';
import RecurringDistributionNewIcon from '../icons/RecurringDistributionNew';
import RecurringDistributionEditIcon from '../icons/RecurringDistributionEdit';
import RolloverTransferIcon from '../icons/RolloverTransfer';
import BeneficiaryClaimsNewIcon from '../icons/BeneficiaryClaimsNew';
import { useGlobalContext } from '../auth/useGlobalContext';
import { AccountType } from '../api/AccountApi.d';
import { useUser } from '../auth/useUser';
import { hasPermission, Permissions } from '../utils/transaction.utils';


function Transactions() {
  let isMounted = true;
  const { organization = {}, allowedWorkflowPermissions } = useGlobalContext();
  const { user } = useUser();
  const { accountTypesSupported = [] } = organization;
  const [workflowPermissions, setWorkflowPermissions] = useState<any>([]);
  // Is at least one inherited account type allowed by the organization?
  // We limit Traditional/Roth options in the workflow
  const allowInheritedIras = accountTypesSupported.some((type) =>
    [AccountType.inheritedRoth, AccountType.inheritedTraditional].includes(type)
  );


  useEffect(() => {
    if (allowedWorkflowPermissions) {
      setWorkflowPermissions(allowedWorkflowPermissions);
    }
    return () => {
      isMounted = false;
    };
  }, [allowedWorkflowPermissions]);

  return (
    <Layout>
      <PaperlessPage>
        <>
          <Typography
            data-qa="transactions_Header"
            variant="overline"
            gutterBottom
          >
            Transactions
          </Typography>

          <Grid container spacing={3} wrap="wrap" mt={2}>
            {hasPermission(Permissions.ACCOUNTS, workflowPermissions) && (
              <ActionCard
                dataQa="open-account"
                iconImage={<AccountNewIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Open New Account"
                route="/transactions/newAccount"
              />
            )}

            {hasPermission(Permissions.DISTRIBUTIONS, workflowPermissions) && (
              <ActionCard
                dataQa="distribute-money"
                iconImage={<RemoveMoneyIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Distribute Money"
                route="/transactions/distributions"
              />
            )}

            {hasPermission(Permissions.CONTRIBUTIONS, workflowPermissions) && (
              <ActionCard
                dataQa="deposit-money"
                iconImage={<AddMoneyIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Deposit Money"
                route="/transactions/contributions"
              />
            )}

            {hasPermission(Permissions.BENEFICIARIES, workflowPermissions) && (
              <ActionCard
                dataQa="update-beneficiaries"
                iconImage={<BeneficiariesEditIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Update Beneficiaries"
                route="/transactions/beneficiaries"
              />
            )}

            {hasPermission(Permissions.RECURRING_DISTRIBUTIONS, workflowPermissions) && (
              <ActionCard
                dataQa="add-recurring-distribution"
                iconImage={<RecurringDistributionNewIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Add Recurring Distribution"
                route="/transactions/recurringDistributions"
              />
            )}

            {hasPermission(Permissions.TRANSFER_REQUESTS, workflowPermissions) && (
              <ActionCard
                dataQa="request-rollover-transfer"
                iconImage={<RolloverTransferIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Request Rollover/Transfer"
                route="/transactions/rolloverTransfer"
              />
            )}

            {allowInheritedIras && hasPermission(Permissions.INHERITED_ACCOUNTS, workflowPermissions) && (
              <ActionCard
                dataQa="new-inherited-ira"
                iconImage={<BeneficiaryClaimsNewIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Open New Inherited IRA"
                route="/transactions/inheritIra"
              />
            )}

            {hasPermission(Permissions.RECURRING_DISTRIBUTIONS, workflowPermissions) && (
              <ActionCard
                dataQa="edit-recurring-distribution"
                iconImage={<RecurringDistributionEditIcon color="secondary" style={{ fontSize: 42 }} />}
                buttonText="Edit Recurring Distribution"
                route="/transactions/recurringDistributions?editActive=true"
              />
            )}
          </Grid>
        </>
      </PaperlessPage>
    </Layout>
  );
}

export default Transactions;
