// this is a universal component that is a navigation drawer that
// comes in from whatever direction the developer sets it to be

import React from 'react';
import { Drawer, DrawerProps } from '@mui/material';

interface SiraDrawerProps extends DrawerProps {
  children: React.ReactNode;
}

export const SiraDrawer: React.FC<SiraDrawerProps> = ({
  children,
  ...props
}) => {
  return (
    <Drawer
      {...props}
      sx={{
        width: '80%',
        flexShrink: 0,
        position: 'absolute',
        top: '50%', 
        bottom: '50%',
        left: '0%', 
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: '85%',
          boxSizing: 'border-box',
          boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.5)',
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          overflowY: 'none',
          padding:2,
        },
      }}
    >
      {children}
    </Drawer>
  );
};
