import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import NumberFormat from 'react-number-format';
import { Contribution5498Correction } from '../../../api/ContributionApi.d';
import StepButtonBar from '../../steps/StepButtonBar';
import {
  Account,
  AccountStatus,
  AccountStatusState,
} from '../../../api/AccountApi.d';

import TransactionSubStepper, {
  TransactionSubStep,
} from '../../../page/TransactionSubStepper';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';
import { useUser } from '../../../auth/useUser';
import { getAccountOwner } from '../../../api/AccountOwnerApi';
import AccountSearchForm from '../accountSearch/AccountSearchForm';
import AccountSearchResults from '../accountSearch/AccountSearchResults';
import {
  setSearchResponse,
  setSelectedAccount,
  useTransactionReducer,
} from '../../../page/TransactionReducer';
import { changeAccountStatus, getAccount } from '../../../api/AccountApi';
import SiraAccountOwnerInfo from '../SiraAccountOwnerInfo';
import SiraCurrencyField from '../SiraCurrencyField';
import CorrectionOwnerAccounts from '../../accountOwnerProfile/CorrectionOwnerAccount';
import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import SiraCheckbox from '../SiraCheckbox';
import { BatchType } from '../../../api/BatchApi.d';
import { errorMessages } from '../../../utils/errorhandling.utils';
import SiraTextField from '../SiraTextField';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { globalPaginationOptions } from '../../../app.constants';
import { getTaxDistributionReason } from '../../../api/TaxFormApi';
import SiraSelectField, { SiraSelectItem } from '../SiraSelectField';
import { initial } from 'cypress/types/lodash';
import SiraSwitchField from '../SiraSwitchField';
import { Padding } from '@mui/icons-material';

export const CONTRIBUTION_INFO_CORRECTION_INIT: Contribution5498Correction = {
  accountId: 0,
  accountOwnerId: 0,
  accountOwnerName: '',
  accountType: '' as any,
  id: 0,
  irsTaxFileUploadId: 0,
  matching: false,
  field: '' as any,
  superiorValue: '',
  fileValue: '',
  record: '',
  data: '',
  depositValue: '',
  grouping: {},
  distributionReason: '',
  overrideContributionLimits: false,
};

export interface ContributionInfoFormProps {
  account: Account;
  initialValues: Contribution5498Correction;
  onSubmit?: Function;
  onCancel?: Function;
  explodeSteps?: boolean; // Should we skip the stepper and show all steps expanded at once?
  errorDetails?: string;
  accountOwner?: AccountOwner;
  batchType?: BatchType;
}

export interface CorrectAccountOwnerFormProps {
  accountId: string;
  accountOwnerId: string;
  correctAccountOwner: Function;
  accountOwner: AccountOwner;
}

function CorrectAccountOwnerForm({
  accountId, // The account to correct
  accountOwnerId, // The account owner to correct
  correctAccountOwner,
  accountOwner,
}: CorrectAccountOwnerFormProps) {
  let isMounted = true;
  const { user } = useUser();
  const { addGlobalMessage } = useGlobalContext();
  const [pageState] = useTransactionReducer();
  const [owner, setAccountOwner] = React.useState(accountOwner as AccountOwner);
  // set pageState.selectedAccount to the account to correct if there is an accountId
  const [selectedAccount, setCorrectionSelectedAccount] = React.useState(
    pageState.selectedAccount,
  );
  const { query = '' } = pageState.searchResponse || {};
  const [changeAccountOwner, setChangeAccountOwner] = useState(
    false as boolean,
  );
  // const [response, setResponse] = useState({});
  const [showReopenAccount, setShowReopenAccount] = useState(false as boolean);

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(): Promise<void> {
    const ownerId = accountOwner.accountOwnerId
      ? accountOwner.accountOwnerId
      : accountOwnerId;
    const id = selectedAccount.accountId
      ? selectedAccount.accountId
      : accountId;
    await getAccount(id, ownerId, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setCorrectionSelectedAccount(res.data);
          setSelectedAccount(res.data);
          setShowReopenAccount(res.data.accountStatus === AccountStatus.closed);
        }
      })
      .catch((err) => {
        if (isMounted) {
          addGlobalMessage(
            errorMessages(err) || 'Could not fetch the preselected account',
          );
        }
      });
  }

  // Handle the reopen account button
  const handleReopenAccount = async (): Promise<void> => {
    // Save updated account fields when editing
    // remove responsibleCountry from data object

    const cleanedData = {
      ...pageState.selectedAccount,
      accountStatus: AccountStatus.previous,
    };

    if (cleanedData.responsibleCountry) {
      delete cleanedData.responsibleCountry;
    }

    const ownerId = owner.accountOwnerId
      ? owner.accountOwnerId
      : accountOwnerId;
    const id = selectedAccount.accountId
      ? selectedAccount.accountId
      : accountId;

    await changeAccountStatus(
      user.organizationId,
      id,
      ownerId,
      AccountStatusState.previous,
      {
        openDate: cleanedData.openDate,
      },
      user.token,
      user,
    )
      .then((res) => {
        if (isMounted) {
          setSelectedAccount(res.data);
          setCorrectionSelectedAccount(res.data);
          setShowReopenAccount(false);
          addGlobalMessage('Account successfuly reopened', {
            severity: 'success',
          });
        }
      })
      .catch((err) => {
        addGlobalMessage(errorMessages(err) || 'Error saving account details');
      });
  };

  // Handle the change account owner button
  const handleChangeAccountOwner = () => {
    setChangeAccountOwner(true);
  };

  // Handle the create new account button
  const handleCreateNewAccount = () => {
    setSelectedAccount({
      ...accountOwner,
      accountOwnerId: owner.accountOwnerId,
    });
    // if we have an account owner id, pass it along and open in a new
    if (accountOwner.accountOwnerId && accountId) {
      window.open(
        `/transactions/newAccount?accountOwnerId=${accountOwner.accountOwnerId}&accountId=${accountId}`,
        '_blank',
      );
    } else {
      window.open(`/transactions/newAccount`, '_blank');
    }
  };
  // Fetch the account owner for the account to correct
  // Get the accountOwner for the account loaded
  async function fetchAndSetAccountOwner(id: string): Promise<void> {
    await getAccountOwner(id, user.organizationId, user.token, user)
      .then((res) => {
        if (isMounted) {
          setAccountOwner(res.data);

          if (id && accountId) {
            fetchAndSetAccount();
          }
        }
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account owner',
        );
      });
  }

  useEffect(() => {
    if (accountOwner.accountOwnerId) {
      const id = accountOwner.accountOwnerId;

      // initialize account reopen button
      fetchAndSetAccountOwner(id);
    } else {
      // initialize the account owner also initialize the selected account
      setCorrectionSelectedAccount({});
    }
    return () => {
      isMounted = false;
    };
  }, [accountOwner]);

  const setAcccountsSelection = (account) => {
    setCorrectionSelectedAccount(account);
    correctAccountOwner(account);
  };

  return (
    <Box mt={4} mb={4}>
      <Grid container>
        {changeAccountOwner ? (
          <>
            <Grid item xs={12}>
              <Box mt={2} mb={4}>
                <AccountSearchForm
                  setResponse={setSearchResponse}
                  owner={owner}
                />
              </Box>
              {query && (
                <AccountSearchResults
                  filterFunction={({
                    account: {
                      accountStatus: statusMatcher = '' as AccountStatus,
                    } = {},
                  }) =>
                    [AccountStatus.open, AccountStatus.closed].includes(
                      statusMatcher,
                    )
                  }
                  response={pageState.searchResponse}
                  onResultClick={(account) => {
                    setCorrectionSelectedAccount(account);
                    setSelectedAccount(account);
                    setChangeAccountOwner(false);
                    setAccountOwner(account);
                    setSearchResponse({});
                    correctAccountOwner(account);
                    // do the check for move forward here?
                  }}
                />
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Box mt={2} mb={4}>
                <Paper elevation={3}>
                  <SiraAccountOwnerInfo
                    selectedAccount={selectedAccount}
                    accountOwner={owner}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} mb={4}>
                {showReopenAccount ? (
                  <Button
                    style={{
                      marginRight: '10px',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleReopenAccount}
                  >
                    Reopen Account
                  </Button>
                ) : null}
                <Button
                  style={{
                    marginRight: '10px',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleChangeAccountOwner}
                >
                  Change Account
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateNewAccount}
                  endIcon={<OpenInNewIcon />}
                >
                  Create New Account
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {owner.accountOwnerId && accountId
                ? null
                : owner.accountOwnerId && (
                  <CorrectionOwnerAccounts
                    accountOwner={accountOwner}
                    setAccount={setAcccountsSelection}
                  />
                )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

function ContributionInfoCorrectionForm({
  account = {} as Account,
  initialValues,
  onSubmit,
  onCancel,
  explodeSteps = false,
  errorDetails,
  accountOwner,
  batchType,
}: ContributionInfoFormProps) {
  const { organization, addGlobalMessage } = useGlobalContext();
  const { user } = useUser();
  const { financialOrganizationId } = organization;

  // retrieve values from formik initialValues
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedAccount, setCorrectionSelectedAccount] = React.useState({
    ...account,
  });
  const [fieldStates, setFieldStates] = useState({});

  const { irsFieldData } = useGlobalContext();
  const { globalPageSize } = usePaginationContext();
  const [distributionReasons, setDistributionReasons] = useState<
    Array<SiraSelectItem>
  >([]);
  const [showDistributionReasons, setShowDistributionReasons] = useState(false);

  const classes = {
    tableLabel: {
      marginBottom: '1.5rem',
    },
    tableCell: {
      borderBottom: 'none',
      borderRight: '1px solid grey',
    },
    headCell: {
      paddingTop: 0,
      borderBottom: 'none',
      borderRight: '1px solid grey',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    lastHeadCell: {
      paddingTop: 0,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    root: {
      overflowX: 'none' as any,
    },
    formContainer: {
      [theme.breakpoints.down('md')]: {
        flexShrink: 0,
      },
      [theme.breakpoints.up('sm')]: {
        flexShrink: 1.5,
      },
    },
  };

  // Grouping is a list of investmentIds that are grouped together
  const { grouping } = initialValues;

  // set the disabled value for the reconcile field
  // const [disabled, setDisabled] = useState(false);
  const columns: GridColDef[] = [
    {
      field: 'deleted',
      headerName: 'Delete Record',
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index; // Access index from row data

        return (
          <Tooltip
            title="Select if the data in Superior is correct and you do not want to make changes from this record"
            style={{
              fontSize: '1rem',
            }}
          >
            <Box
              sx={{
                display: !grouping.details[index].matching ? 'flex' : 'none',
                alignItems: 'center',
              }}
            >
              <Field
                name={`grouping.details[${index}.deleted]`}
                component={SiraCheckbox}
                type="checkbox"
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'fieldDisplay', // New field for display
      headerName: 'Field',
      flex: 2,
      renderCell: (params) => {
        const index = params.row.index; // Access index from row data
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 1,
              maxHeight: '2rem',
            }}
          >
            <Icon
              sx={{
                paddingRight: 1,
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              {grouping.details[index].matching ? (
                <CheckCircleIcon color="success" />
              ) : (
                <ErrorIcon color="error" />
              )}
            </Icon>
            <Typography variant="body2">
              {irsFieldData[grouping.details[index].field]}
            </Typography>
          </Box>
        );
      },
    },
    // ... other columns
    {
      field: 'fileValueDisplay', // New field for display
      headerName: 'File Value',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Typography variant="body2" sx={{ pt: '15px' }}>
            {row.field !== 'REPAYMENT_CODE' ? (
              <NumberFormat
                value={row.fileValue || 0}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            ) : (
              row.fileValue
            )}
          </Typography>
        );
      },
    },
    {
      field: 'superiorValueDisplay', // New field for display
      headerName: 'Superior Value',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Typography variant="body2" sx={{ pt: '15px' }}>
            {row.field !== 'REPAYMENT_CODE' ? (
              <NumberFormat
                value={row.superiorValue || 0}
                prefix="$"
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
                decimalScale={2}
              />
            ) : (
              row.superiorValue
            )}
          </Typography>
        );
      },
    },
    {
      field: 'reconcileAmount',
      headerName: 'Reconcile Amount',
      flex: 1,
      renderCell: (params) => {
        const index = params.row.index;
        return (
          <Box
            sx={{
              pt: '12px',
              maxHeight: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {params.row.field !== 'REPAYMENT_CODE' ? (
              <SiraCurrencyField
                name={`grouping.details[${index}].reconcileAmount`}
                disabled={fieldStates[index]}
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: 'center',
                  '& .MuiInputBase-input': { padding: '0px' },
                }}
              />
            ) : (
              <SiraTextField
                name={`grouping.details[${index}].reconcileAmount`}
                disabled={fieldStates[index]}
                sx={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  textAlign: 'center',
                  '& .MuiInputBase-input': { padding: '0px' },
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const retreiveDistributionReasons = useCallback(async () => {
    try {
      const res = await getTaxDistributionReason(
        financialOrganizationId,
        user.token,
        selectedAccount.accountOwnerId,
        selectedAccount.accountId,
      );
      setDistributionReasons(res.data);
    } catch (err) {
      addGlobalMessage(
        errorMessages(err) || 'Could not fetch the distribution reasons',
      );
    }
  }, [
    financialOrganizationId,
    user.token,
    selectedAccount.accountOwnerId,
    selectedAccount.accountId,
  ]);

  useEffect(() => {
    // use this to grab the distribution reason from the constants
    if (
      batchType !== BatchType.taxReport5498 &&
      selectedAccount.accountOwnerId
    ) {
      setShowDistributionReasons(true);
      retreiveDistributionReasons(); // get the distribution reasons
    }
  }, [batchType, selectedAccount.accountOwnerId]);

  const rows = React.useMemo(() => {
    if (initialValues.grouping) {
      return initialValues.grouping.details.map((detail, index) => ({
        ...detail,
        id: index,
        index: index,
        fieldDisplay: irsFieldData[detail.field],
        distributionReasonDisplay: detail.distributionReason,
        fileValueDisplay: detail.fileValue,
        superiorValueDisplay: detail.superiorValue,
      }));
    } else return [];
  }, [initialValues.grouping, irsFieldData]);

  const contributionSubSteps: Array<TransactionSubStep> = [
    {
      label: 'Current Account Owner Information',
      stepContent: (
        <CorrectAccountOwnerForm
          accountId={account.accountId}
          accountOwnerId={account.accountOwnerId}
          correctAccountOwner={setCorrectionSelectedAccount}
          accountOwner={accountOwner}
        />
      ),
      validationFields: [],
    },
    {
      label:
        !showDistributionReasons ? `Enter the correct value in the <strong>Reconcile</strong>  <strong>Amount</strong>  box for each row marked with  icon  and click <strong>Continue</strong> .
       If the transaction should not be applied or has already been added under the owner's account, check the <strong>Delete</strong>  <strong>Record</strong>  box and click <strong>Continue</strong> . `
          : `Select the correct <strong>Distribution</strong>  <strong>Reason</strong> , enter the correct value in the <strong>Reconcile</strong>  <strong>Amount</strong>  box for each row marked with  icon  and click <strong>Continue</strong> .
       If the transaction should not be applied or has already been added under the owner's account, check the <strong>Delete</strong>  <strong>Record</strong>  box and click <strong>Continue</strong> . `,
      stepContent: (
        <Grid container spacing={3} sx={{ pt: 2 }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="subtitle1" gutterBottom color="error">
              {errorDetails}
            </Typography>
          </Grid>
          {batchType !== BatchType.taxReport5498 ? (
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle1" gutterBottom>
                <SiraSelectField
                  label="Distribution Reason"
                  name="distributionReason"
                  items={distributionReasons}
                />
              </Typography>
            </Grid>
          ) : null}
          {
            batchType === BatchType.taxReport5498 ? (
              <Grid item xs={12} sm={8}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Box>
                      <SiraSwitchField
                        name="overrideContributionLimits"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Override Contribution Limit
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null

          }
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 3, width: '100%' }}>
              <CardContent>
                <DataGridPro
                  autoHeight
                  columns={columns}
                  rows={rows}
                  disableRowSelectionOnClick
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: globalPageSize,
                      },
                    },
                  }}
                  pagination
                  pageSizeOptions={globalPaginationOptions}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ),
      validationFields: [],
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit({ ...values, ...selectedAccount });
      }}
      enableReinitialize
    >
      {({ isSubmitting, values, setFieldValue }) => {
        // set the disabled value for the reconcile field based on the deleted value in values changing
        useEffect(() => {
          const newFieldStates = {};
          if (values.grouping) {
            values.grouping.details.forEach((detail, index) => {
              newFieldStates[`${index}`] = detail.deleted;
              // set the value of the reconcile field in formik to 0 if the deleted field is true
              if (detail.deleted) {
                setFieldValue(`grouping.details[${index}].reconcileAmount`, 0);
                setFieldValue(
                  `grouping.details[${index}].distributionReason`,
                  null,
                );
              }
            });
          }
          setFieldStates(newFieldStates);
        }, [values, setFieldValue]);

        // set the distribution reason for the dropdown since all the distributions roolup into one
        // this will happen on initial load
        useEffect(() => {
          if (
            initialValues.grouping?.details.length > 0 &&
            initialValues.grouping.details[0]?.distributionReason
          ) {
            setFieldValue(
              'distributionReason',
              initialValues.grouping.details[0].distributionReason,
            );
          }
        }, [initialValues, setFieldValue]);

        return (
          <Grid container wrap="nowrap" sx={classes.root}>
            <Grid item xs={12} sx={classes.formContainer}>
              <TransactionSubStepper
                steps={contributionSubSteps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                explodeSteps={explodeSteps}
              />
              <Form>
                <StepButtonBar
                  isSubmitting={isSubmitting}
                  onCancel={onCancel}
                />
              </Form>
              {isSubmitting && (
                <Box
                  sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                  }}
                >
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

export default ContributionInfoCorrectionForm;
