import axios from 'axios';
import format from 'string-format';
import { format as formatDate } from 'date-fns';

import { prepareBinaryJson, removeEmptyStrings, setBaseUrl } from './api.utils';
import {
  Branch,
  BranchResponse,
  CustomDistributionCodeDTO,
  CustomDistributionCodeResponse,
  DefaultRmd,
  FinOrgDocResponse,
  Organization,
  OrganizationEsignSettingsResponse,
  OrganizationResponse,
  OrganizationSearchResponse,
  OrganizationTaxReportingSettings,
  OrganizationTaxReportingSettingsResponse,
  StateTaxId,
  StateTaxIdResponse,
} from './OrganizationApi.d';
import { OrganizationSearchFormData } from '../components/form/orgSearch/OrganizationSearch';
// import { stateWithholdingCertFieldMap } from '../components/form/organization/StateTaxIdForm';
import { User, UserRole } from './UserApi.d';
import { AccountStatus, AccountType } from './AccountApi.d';
import { BeneficiaryStatus } from './BeneficiariesApi.d';
import { ContributionStatus } from './ContributionApi.d';
import { DistributionStatus } from './DistributionApi.d';
import { RecurringDistributionStatus } from './RecurringDistributionApi.d';
import { TransferRequestStatus } from './TransferApi.d';
import {
  DefaultRmdFormData,
  AllowedDistribution,
} from '../components/form/recurringDistributions/DefaultSettingsForm.d';
import axiosInstance from '../utils/axiosInterceptor';
import { AccountSummaryTypes } from '../page/ReportsAccountsSummary';

const urlBase = process.env.REACT_APP_API_BASE_URL;
const orgUrl = '/api/org/{0.orgId}';
const customerPortalOrgUrl = '/api/customerPortal/org/{0.orgId}';
const dashboardUrl = '/api/org/{0.orgId}/dashboard';
const dashboardContentUrl = '/api/org/{0.orgId}/dashboard/accounts/new';
const branchUrl = '/api/org/{0.orgId}/branch/{0.branchId}';
const stateTaxIdUrl = '/api/org/{0.orgId}/taxid/{0.state}';
const orgSearchUrl = '/api/org/search?text={0.query}';
const supportedAccountsUrl = '/api/org/{0.orgId}/supportedAccountTypes';
const workflowPermissionsUrl = '/org/{0.orgId}/workflowpermissions';
const logoUrl = '/api/org/{0.orgId}/upload/logo';
const orgBrandingUrl = '/api/org/{0.orgId}/branding';
const defaultRmdUrl = '/api/org/{0.orgId}/defaultRMD';
const supportedDistributionMethods =
  '/api/org/{0.orgId}/supportedDistributionMethods';
const getDocumentsUrl = '/api/org/{0.orgId}/documents';
const getDocumentUrl = '/api/org/{0.orgId}/document';
const getStateTaxDocumentUrl = '/api/org/{0.orgId}/document/key';
const putDocumentsUrl = '/api/org/{0.orgId}/upload/documents';
const postDocumentsUrl = '/api/org/{0.orgId}/upload/documents';
const deleteDocumentsUrl = '/api/org/{0.orgId}/documents';
// const organizationEsignUrl='/api/org/{0.orgId}/imm/esign/fieldmappings'
// organizational tax reporting settings
const organizationalTax = '/api/org/{0.orgId}/taxDocument/reporting';

const organizationalTransSettingsUrl = '/api/org/{0.orgId}/distributionCode';
const fieldmappingsOrganizationEsignUrl =
  '/api/org/{0.orgId}/imm/esign/fieldmappings';
const retrieveOrganizationEsignUrl = '/api/org/{0.orgId}/imm/settings';
const deleteOrganizationEsignUrl = '/api/org/{0.orgId}/imm/settings/delete';
const postOrganizationEsignUrl = '/api/org/{0.orgId}/imm/settings/upsert';
const postOrganizationFieldMappingEsignUrl =
  '/api/org/{0.orgId}/imm/esign/fieldmappings/upsert';

//core integration settings api
const getCoreIntegrationUrl = '/api/org/{0.orgId}/coreconnectionsettings';
const updateOrCreateCoreIntegrationUrl =
  '/api/org/{0.orgId}/coreconnectionsettings';

function organizationUpdateRequestFactory(data: Organization) {
  const {
    federalTaxId,
    fiduciaryType,
    legalName,
    name,
    accountTypesSupported,
    spousalConsentRequired,
    primaryColorRGB,
    solution,
    agreementDate,
    distributionMethodsSupported,
    documentationAddress,
    investmentRatePrecision,
    eSign,
    eSignApiKey,
    eSignBaseUrl,
    isEsignPassthroughId,
    esignDefaultAppUserId,
    esignUserId,
    esignHostFiid,
    eSignLandingPage,
    eSignWithSuperior,
    autoScheduledDistributionsEnabled,
    withholdingNotificationsEnabled,
    rmdNotificationsEnabled,
    immField,
    superiorField,
    accountNumberValue,
    orgTransactionEmailSetting,
    apyRatePrecision,
    customerPortalUrl,
    sftpFolderName,
    activeDate,
    status,
    sso,
    ipFiltering,
    displayFeeAmount,
    displayInvestments,
    planNumberEnabled,
    accountOwnerPortalCustomError,
    accountOwnerPortalCustomErrorHeader,
    beneyClaimSpecialHandling,
  } = data;

  return {
    federalTaxId,
    fiduciaryType,
    legalName,
    name,
    accountTypesSupported,
    spousalConsentRequired,
    primaryColorRGB,
    solution,
    agreementDate,
    distributionMethodsSupported,
    documentationAddress,
    investmentRatePrecision,
    eSign,
    eSignApiKey,
    eSignBaseUrl,
    isEsignPassthroughId,
    esignDefaultAppUserId,
    esignUserId,
    esignHostFiid,
    eSignWithSuperior,
    eSignLandingPage,
    autoScheduledDistributionsEnabled,
    withholdingNotificationsEnabled,
    rmdNotificationsEnabled,
    immField,
    superiorField,
    accountNumberValue,
    orgTransactionEmailSetting,
    apyRatePrecision,
    customerPortalUrl,
    sftpFolderName,
    activeDate,
    status,
    sso,
    ipFiltering,
    displayFeeAmount,
    planNumberEnabled,
    displayInvestments,
    accountOwnerPortalCustomError,
    accountOwnerPortalCustomErrorHeader,
    beneyClaimSpecialHandling,
  };
}

function branchRequestFactory(data: Branch): Branch {
  const {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    branchId,
    name,
    primaryBranch,
    phoneNumber,
    emailAddress,
  } = data;

  return {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    branchId,
    name,
    primaryBranch,
    phoneNumber,
    emailAddress,
  };
}

// function stateTaxIdRequestFactory(data: StateTaxId): StateTaxId {
//   const { state: selectedState } = data;

//   const withholdingCertFields = Object.entries(
//     stateWithholdingCertFieldMap
//   ).reduce((acc, [state, field]) => {
//     acc[field] =
//       selectedState === state
//         ? IncludeWithholdingCert[Boolean(data[field]).toString()]
//         : IncludeWithholdingCert.default;

//     return acc;
//   }, {});

//   return {
//     ...data,
//     ...withholdingCertFields,
//   };
// }

function defaultRmdRequestFactory(data: DefaultRmdFormData): DefaultRmd {
  const {
    defaultRmdId,
    ownerResponsible,
    distributionMethod,
    electionDeadline,
    toAccountType,
    age72FirstRmdFrequency,
    age72FirstRmdStartDate,
    age72SubsequentRmdFrequency,
    age72SubsequentRmdStartDate,
    age73CurrentRmdFrequency,
    age73CurrentRmdStartDate,
    age73SubsequentRmdFrequency,
    age73SubsequentRmdStartDate,
    age72FirstRmdIsFollowingYear,
  } = data;

  return {
    defaultRmdId,
    ownerResponsible,
    distributionMethod,
    electionDeadline,
    toAccountType,
    age72FirstRmdFrequency,
    age72FirstRmdStartDate:
      age72FirstRmdStartDate &&
      formatDate(new Date(age72FirstRmdStartDate), 'MM-dd'),
    age72SubsequentRmdFrequency,
    age72SubsequentRmdStartDate:
      age72SubsequentRmdStartDate &&
      formatDate(new Date(age72SubsequentRmdStartDate), 'MM-dd'),
    age73CurrentRmdFrequency,
    age73CurrentRmdStartDate:
      age73CurrentRmdStartDate &&
      formatDate(new Date(age73CurrentRmdStartDate), 'MM-dd'),
    age73SubsequentRmdFrequency,
    age73SubsequentRmdStartDate:
      age73SubsequentRmdStartDate &&
      formatDate(new Date(age73SubsequentRmdStartDate), 'MM-dd'),
    age72FirstRmdIsFollowingYear,
  };
}

export async function createOrSaveOrg(data: Organization, token: string) {
  const { financialOrganizationId: orgId = '' } = data;
  const url = `${urlBase}${format(orgUrl, { orgId })}`;
  const dataNorm = removeEmptyStrings(data);

  if (data.financialOrganizationId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateOrg(
  data: Organization,
  org: Organization,
  token: string,
) {
  const url = `${urlBase}${format(orgUrl, {
    orgId: org.financialOrganizationId,
  })}`;
  const dataNorm = removeEmptyStrings(organizationUpdateRequestFactory(data));

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateWorkflowPermissions(
  data: any,
  orgId: string,
  token: string,
  user: User,
) {
  const url = `${urlBase}${format(
    `${setBaseUrl(user)}${workflowPermissionsUrl}`,
    {
      orgId,
    },
  )}`;

  return axiosInstance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function updateCoreIntegrationOrgFields(
  data: any,
  orgId: string,
  token: string,
) {
  const url = `${urlBase}${format(updateOrCreateCoreIntegrationUrl, {
    orgId,
  })}`;
  const dataNorm = data;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateEsignOrgFields(
  data: any,
  orgId: string,
  token: string,
) {
  const url = `${urlBase}${format(postOrganizationEsignUrl, {
    orgId,
  })}`;
  const dataNorm = data;
  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateEsignOrgFieldsMappings(
  data: any,
  orgId: string,
  token: string,
) {
  const url = `${urlBase}${format(postOrganizationFieldMappingEsignUrl, {
    orgId,
  })}`;
  const dataNorm = data;
  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteEsignOrgFields(orgId: string, token: string) {
  const url = `${urlBase}${format(deleteOrganizationEsignUrl, {
    orgId,
  })}`;
  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrg(
  orgId: string,
  token: string,
  user: User,
): Promise<OrganizationResponse> {
  let url = '';
  if (user.roles.includes(UserRole.accountOwner)) {
    url = `${urlBase}${format(customerPortalOrgUrl, { orgId })}`;
  } else {
    url = `${urlBase}${format(orgUrl, { orgId })}`;
  }

  if (!orgId || !token) return null;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgEsignFieldMappings(
  orgId: string,
  token: string,
): Promise<OrganizationEsignSettingsResponse> {
  const url = `${urlBase}${format(fieldmappingsOrganizationEsignUrl, {
    orgId,
  })}`;

  if (!orgId || !token) return null;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgCoreSetting(
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(getCoreIntegrationUrl, { orgId })}`;

  if (!orgId || !token) return null;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrgEsignSetting(
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(retrieveOrganizationEsignUrl, { orgId })}`;

  if (!orgId || !token) return null;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrSaveBranch(
  data: Branch,
  org: Organization,
  token: string,
): Promise<BranchResponse> {
  const { branchId = '' } = data;
  const url = `${urlBase}${format(branchUrl, {
    orgId: org.financialOrganizationId,
    branchId,
  })}`;

  const dataNorm = removeEmptyStrings(branchRequestFactory(data));

  if (branchId) {
    return axiosInstance.put(url, dataNorm, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteBranch(
  data: Branch,
  org: Organization,
  token: string,
): Promise<BranchResponse> {
  const { branchId = '' } = data;
  const url = `${urlBase}${format(branchUrl, {
    orgId: org.financialOrganizationId,
    branchId,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getStateTaxIds(
  org: Organization,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(stateTaxIdUrl, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getBranch(
  org: Organization,
  token: string,
): Promise<BranchResponse> {
  const url = `${urlBase}${format(branchUrl, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createStateTaxId(
  data: StateTaxId,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(stateTaxIdUrl, {
    orgId: org.financialOrganizationId,
    state: '',
  })}`;
  const dataNorm = data;

  return axiosInstance.post(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateStateTaxId(
  data: StateTaxId,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(stateTaxIdUrl, {
    orgId: org.financialOrganizationId,
    state: data.state,
  })}`;
  const dataNorm = data;

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteStateTaxId(
  data: StateTaxId,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(stateTaxIdUrl, {
    orgId: org.financialOrganizationId,
    state: data.state,
  })}`;

  return axiosInstance.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateSupportedAccountTypes(
  data: Array<AccountType>,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(supportedAccountsUrl, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getSupportedAccountTypes(
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(supportedAccountsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getWorkflowPermissions(
  orgId: string,
  token: string,
  user: User,
): Promise<any> {

    const url = `${urlBase}${format(
      `${setBaseUrl(user)}${workflowPermissionsUrl}`,
      {
        orgId,
      },
    )}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createSupportedAccountTypes(
  data: Array<AccountType>,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(stateTaxIdUrl, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function searchOrg(
  data: OrganizationSearchFormData,
  token: string,
): Promise<OrganizationSearchResponse> {
  const { query = '' } = data;
  const url = `${urlBase}${format(orgSearchUrl, { query })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/* DASHBOARD */
export async function getDashboardAccounts(
  orgId: string,
  status: Array<AccountStatus> = [],
  token: string,
  accountId: Array<string> = [],
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, { orgId })}/accounts`;
  const params = { accountId };

  if (status.length) {
    Object.assign(params, { status });
  }

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/* DASHBOARD */
export async function getDashboardAccountContents(
  filter: any,
  dateFilters: any,
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(dashboardContentUrl, {
    orgId,
  })}`;
  const { startDate, endDate } = dateFilters;

  const params = {
    from: startDate,
    to: endDate,
    filter: AccountSummaryTypes[filter],
  };

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/* DASHBOARD */
export async function getAccountSummaryDashboards(
  dateFilters: any,
  orgId: string,
  token: string,
  accountId: Array<string> = [],
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, {
    orgId,
  })}/accounts/summarystats`;
  const { startDate, endDate } = dateFilters;

  const params = {
    from: startDate,
    to: endDate,
  };

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDashboardBeneficiaries(
  orgId: string,
  status: Array<BeneficiaryStatus>,
  token: string,
  accountStatus: Array<AccountStatus>,
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, { orgId })}/beneficiaries`;
  const params = removeEmptyStrings({ status, accountStatus });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDashboardContributions(
  orgId: string,
  status: Array<ContributionStatus>,
  token: string,
  accountStatus: Array<AccountStatus>,
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, { orgId })}/contributions`;
  const params = removeEmptyStrings({ status, accountStatus });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDashboardDistributions(
  orgId: string,
  status: Array<DistributionStatus>,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, { orgId })}/distributions`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDashboardRecurringDistributions(
  orgId: string,
  status: Array<RecurringDistributionStatus>,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, {
    orgId,
  })}/recurringDistributions`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDashboardTransferRequests(
  orgId: string,
  status: Array<TransferRequestStatus>,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(dashboardUrl, {
    orgId,
  })}/transferRequests`;
  const params = removeEmptyStrings({ status });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function uploadLogo(
  file: Array<File>,
  orgId: string,
  token: string,
): Promise<any> {
  const url = `${urlBase}${format(logoUrl, {
    orgId,
  })}`;

  const formData = new FormData();
  formData.append('file', file[0]);

  return axiosInstance.put(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateOrgBranding(
  data: Organization,
  org: Organization,
  token: string,
) {
  const url = `${urlBase}${format(orgBrandingUrl, {
    orgId: org.financialOrganizationId,
  })}`;
  const dataNorm = removeEmptyStrings(data);

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateDefaultRmd(
  data: DefaultRmdFormData,
  orgId: string,
  token: string,
) {
  const url = `${urlBase}${format(defaultRmdUrl, {
    orgId,
  })}`;

  const dataNorm = removeEmptyStrings(defaultRmdRequestFactory(data));

  return axiosInstance.put(url, dataNorm, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateAllowedDistribution(
  data: Array<AllowedDistribution>,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(supportedDistributionMethods, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createAllowedDistribution(
  data: Array<AllowedDistribution>,
  org: Organization,
  token: string,
): Promise<StateTaxIdResponse> {
  const url = `${urlBase}${format(supportedDistributionMethods, {
    orgId: org.financialOrganizationId,
  })}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getFinancialOrganizationDoc(
  superiorUser: User,
  org: string,
  token: string,
): Promise<FinOrgDocResponse> {
  const url = `${urlBase}${format(getDocumentsUrl, {
    orgId: org,
  })}`;
  const params = removeEmptyStrings({ superiorUser });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getIndividualFinancialOrganizationDoc(
  fileName: string,
  org: string,
  token: string,
) {
  const url = `${urlBase}${format(getDocumentUrl, {
    orgId: org,
  })}`;
  const params = removeEmptyStrings({ fileName });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });
}

export async function getStateTaxDoc(key, org: string, token: string) {
  const url = `${urlBase}${format(getStateTaxDocumentUrl, {
    orgId: org,
  })}`;
  const params = removeEmptyStrings({ fileKey: key });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function putFinancialOrganizationDoc(
  data: Array<File>,
  description: string,
  org: string,
  token: string,
) {
  const url = `${urlBase}${format(putDocumentsUrl, {
    orgId: org,
  })}`;

  const formData = new FormData();

  formData.append('file', data[0]);
  formData.append('description', description);

  return axiosInstance.put(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createFinancialOrganizationDocs(
  files: Array<File>,
  fileProperties: string,
  org: string,
  token: string,
) {
  const url = `${urlBase}${format(postDocumentsUrl, {
    orgId: org,
  })}`;

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  if (fileProperties && fileProperties.length > 0) {
    formData.append('fileProperties', prepareBinaryJson(fileProperties));
  }

  return axiosInstance.post(url, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteFinancialOrganizationDoc(
  fileName: string,
  org: string,
  token: string,
): Promise<void> {
  const url = `${urlBase}${format(deleteDocumentsUrl, {
    orgId: org,
  })}`;

  const params = removeEmptyStrings({ fileName });

  return axiosInstance.delete(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function putOrganizationalSettings(
  data: any,
  taxYear: number,
  orgId: string,
  token: string,
): Promise<OrganizationTaxReportingSettingsResponse> {
  const url = `${urlBase}${format(organizationalTax, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear });

  return axiosInstance.put(url, data, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrganizationalSettings(
  taxYear: number,
  orgId: string,
  token: string,
): Promise<OrganizationTaxReportingSettingsResponse> {
  const url = `${urlBase}${format(organizationalTax, {
    orgId,
  })}`;

  const params = removeEmptyStrings({ taxYear });

  return axiosInstance.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getOrganizationTransactionSettings(
  orgId: string,
  token: string,
): Promise<CustomDistributionCodeResponse> {
  const url = `${urlBase}${format(organizationalTransSettingsUrl, {
    orgId,
  })}`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function putOrganizationTransactionSettings(
  data: CustomDistributionCodeDTO,
  orgId: string,
  token: string,
): Promise<CustomDistributionCodeResponse> {
  const url = `${urlBase}${format(organizationalTransSettingsUrl, {
    orgId,
  })}`;

  return axiosInstance.put(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function deleteOrganizationTransactionSettings(
  distributionReason: string,
  org: string,
  token: string,
): Promise<void> {
  const url = `${urlBase}${format(organizationalTransSettingsUrl, {
    orgId: org,
  })}`;

  const params = removeEmptyStrings({ distributionReason });

  return axiosInstance.delete(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createOrganizationTransactionSettings(
  data: CustomDistributionCodeDTO,
  orgId: string,
  token: string,
): Promise<CustomDistributionCodeResponse> {
  const url = `${urlBase}${format(organizationalTransSettingsUrl, {
    orgId,
  })}`;

  return axiosInstance.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
