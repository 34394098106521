import React, { memo, useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';

import StepButtonBar from '../../steps/StepButtonBar';
import SiraCheckbox from '../SiraCheckbox';
import { useGlobalContext } from '../../../auth/useGlobalContext';

export interface OrgOptionsFormData {
  workflowPermission: Array<any>;
}

export const ORG_WORKFLOWS_TRANSACTION_INIT: OrgOptionsFormData = {
  workflowPermission: [],
};

export interface OrgOptionsFormProps {
  initialValues: OrgOptionsFormData;
  onSubmit?: Function;
  onReset?: Function;
  submitName?: string;
  resetName?: string;
}

function OrgWorkflowsForm({
  initialValues,
  onSubmit = () => { },
  onReset = () => { },
  submitName,
  resetName,
}: OrgOptionsFormProps) {
  const { workflowPermisions } = useGlobalContext();
  const [transactionPermissions, setTransactionPermissions] = useState<Array<any>>([]);

  useEffect(() => {
    if (workflowPermisions) {
      // set the workflow permisions to value and lable 
      // the response coming from session storage is an object create an array of objects
      const workflowArray = Object.keys(workflowPermisions).map((key) => ({
        value: key,
        label: workflowPermisions[key].displayName,
      }));

      setTransactionPermissions(workflowArray);

    }
  }, [workflowPermisions]);

  return (
    <section>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          await onSubmit(values);
        }}
        onReset={() => onReset()}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                {/* Supported Account Types */}
                <Grid item xs={12}>
                  <Typography>
                    Transaction flows that this organization can access
                  </Typography>
                  {transactionPermissions.map(({ value, label }) => (
                    <Box mt={1.5} key={value}>
                      <Field
                        name="workflowPermission"
                        value={value}
                        label={label}
                        type="checkbox"
                        component={SiraCheckbox}
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>

              <StepButtonBar
                isSubmitting={isSubmitting}
                submitName={submitName}
                resetName={resetName}
              />
            </Form>
          );
        }}
      </Formik>
    </section>
  );
}

export default memo(OrgWorkflowsForm);
