/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Grid, Box, Typography, Alert, LinearProgress, debounce, Tooltip, Button, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  DataGridPro,
  GridColDef,
  GridCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridRowId,
  GridPaginationMeta,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { globalPaginationOptions } from '../../../app.constants';
import { useUser } from '../../../auth/useUser';
import {
  BatchType,
  TaxRepotingImportDetails,
  TaxResult,
} from '../../../api/BatchApi.d';
import {
  useGlobalContext,
  usePaginationContext,
} from '../../../auth/useGlobalContext';

import { ConstantsMappingKey } from '../../../api/SharedTextApi.d';
import SiraNoRowsOverlay from '../../SiraNoRowsOverlay';
import { getAccount } from '../../../api/AccountApi';

import ContributionInfoCorrectionForm, {
  CONTRIBUTION_INFO_CORRECTION_INIT,
} from '../../form/newAccount/ContributionInfoCorrectionForm';
// import SiraTaxFormCorrectionModal from '../../form/SiraTaxFormCorrectionModal';
import {
  deleteTaxFile,
  getCorrectionData,
  taxReportingCorrectionRequest,
} from '../../../api/TaxFormApi';
import { Account } from '../../../api/AccountApi.d';
import { AccountOwner } from '../../../api/AccountOwnerApi.d';
import TaxReportingFileCards from './TaxReportingFileCards';
import { errorMessages } from '../../../utils/errorhandling.utils';
import { SiraDrawer } from '../../SiraDrawer';
import { set } from 'cypress/types/lodash';
import { fi } from 'date-fns/locale';

interface BatchInfoListProps {
  batchData: Array<TaxResult>;
  isLoading: boolean;
  onComplete?: Function;
  batchType?: BatchType;
  fetchIrsTaxFile: Function;
  batchFileData: any;
  totalRowCount: number;
  hasNextPage: boolean;
  handleSortChange: Function;
  handleFilterChange: Function;
  filterOptions?: any;
}
interface BatchInfoCardProps {
  batchType?: BatchType;
  batchInfo: Array<TaxRepotingImportDetails>;
  onComplete?: Function;
  totalRowCount: number;
  hasNextPage: boolean;
  handleSortChange: Function;
  handleFilterChange: Function;
  filterOptions?: any;
  refreshData?: Function;
}

export interface GroupedData {
  [key: string]: Array<any>;
}

function BatchInfoCard(props: BatchInfoCardProps) {
  const {
    batchInfo = [],
    onComplete,
    batchType,
    totalRowCount,
    hasNextPage,
    handleSortChange = () => { },
    handleFilterChange = () => { },
    filterOptions = {},
    refreshData = () => { },
  } = props;
  const { user } = useUser();
  const { getAppConstant, addGlobalMessage } = useGlobalContext();
  // flatten data and set the row data
  const [rowData, setRowData] = useState([] as Array<any>);
  const { globalPageSize, setGlobalPageSize } = usePaginationContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState('' as string);
  const [description, setDescription] = useState<string>('')
  const [selectedRowData, setSelectedRowData] = useState({} as any);
  const [selectedAccount, setCorrectionSelectedAccount] = useState(null);
  const [selectedAccountOwner, setCorrectionSelectedAccountOwner] = useState(
    {} as AccountOwner
  );
  const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
  const [selectedRows, setSelectedRows] = useState([]);
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, startTransition] = useTransition();

  // using this debounce to prevent multiple calls to the api until the filter is done
  const debouncedFilter = useRef(
    debounce((filter) => {
      handleFilterChange(filter);
    }, 600)
  );

  const correctionName = useMemo(() => {
    if (batchType === BatchType.taxReport1099) {
      return '1099';
    }

    return '5498';
  }, [batchType]);

  // Look up account and set it selected with query params passed
  async function fetchAndSetAccount(
    id: string,
    ownerId: string
  ): Promise<void> {
    await getAccount(id, ownerId, user.organizationId, user.token, user)
      .then((res) => {
        setCorrectionSelectedAccount(res.data);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the preselected account'
        );
      });
  }

  // Optionaly set account and contribution when passed query params
  async function preselectAccountAndSetInfo(row): Promise<void> {
    const { accountOwnerId, accountId, id } = row;

    if (accountId && accountOwnerId) {
      await fetchAndSetAccount(accountId, accountOwnerId);
    } else {
      setCorrectionSelectedAccount({ accountId } as Account);
    }

    if (id) {
      setModalOpen(true);
    }
  }

  const handleSelectionChange = (newSelection) => {
    // add the selected rows into the selected rows state pull value from the api
    const cleanedData = Array.from(newSelection.values());
    setSelectedRows(cleanedData);
  };

  const columns: GridColDef[] = [
    {
      field: 'matching',
      headerName: 'Matching',
      width: 85,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const { value = '', row = {} } = params;
        const isMatching = value;
        const { deleted } = row;

        // show warning icon if not matching and center the icon
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {isMatching ? (
              deleted ? (
                <Tooltip title={`${row.description}`}>
                  <DeleteForeverOutlinedIcon color="success" />
                </Tooltip>
              ) : (
                <CheckCircleIcon color="success" />
              )
            ) : (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  retrieveData(row);
                }}
              >
                <ErrorIcon color="error" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    { field: 'accountOwnerName', headerName: 'Account Owner Name', width: 200 },
    {
      field: 'accountType',
      headerName: 'Type',
      width: 200,
      valueGetter: (value) => {
        return getAppConstant(ConstantsMappingKey.accountType, value);
      },
      renderCell: (params: GridCellParams) => {
        const { value = '' } = params;
        const typeId = value.toString();

        return (
          <Box overflow="hidden" textOverflow="ellipsis">
            {getAppConstant(ConstantsMappingKey.accountType, typeId)}
          </Box>
        );
      },
    },
    { field: 'field', headerName: 'Field', width: 200 },
    {
      field: 'superiorValue',
      headerName: 'Superior Value',
      width: 200,
      editable: true,
    },
    { field: 'fileValue', headerName: 'File Value', width: 200 },
    {
      field: 'errorDescription',
      headerName: 'Error Description',
      width: 200,
    },
  ];

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // so we are going to take the accountDetails array and create a new object with
    //  accountowner name and account type
    //  and then we will create a new array of objects with the accountOwnerName and accountType
    const flatteningData = [...batchInfo];

    startTransition(() => {
      setRowData(flatteningData);
    });

  }, [batchInfo]);

  const paginationMetaRef = useRef<GridPaginationMeta>();

  // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
  const paginationMeta = useMemo(() => {
    if (
      hasNextPage !== undefined &&
      paginationMetaRef.current?.hasNextPage !== hasNextPage
    ) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  const useStyles = makeStyles(() => ({
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '15px',
    },
    exportButton: {
      backgroundColor: 'black', // Set your desired background color
      color: 'white', // Set your desired text color
      '&:hover': {
        backgroundColor: 'black', // Set your desired hover background color
      },
      padding: '5px',
    },
    backdrop: {
      zIndex: 9999,
      color: '#fff',
    },
    blurred: {
      filter: 'blur(4px)',
      pointerEvents: 'none',
    },
  }));

  const classes = useStyles();

  const onDelete = async () => {
    let data: Array<{
      sequenceNumber: number,
      detailIds: Array<string>,
    }> = [];

    // we need to build the data where selected rows are grouped by sequence number and the selected rows id are added to the details
    data = selectedRows.reduce((acc, row) => {
      const { sequenceNumber } = row;
      const existing = acc.find((item) => item.sequenceNumber === sequenceNumber);

      if (existing) {
        existing.detailIds.push(row.id);
      } else {
        acc.push({
          sequenceNumber: sequenceNumber,
          detailIds: [row.id],
        });
      }

      return acc;
    }, []);

    const uploadIds = selectedRows.map((row) => row.irsTaxFileUploadId);

    setIsLoading(true);

    await deleteTaxFile(
      user.organizationId,
      user.token,
      uploadIds[0],
      data,
      user
    )
      .then(() => {
        // remove deleted rows from the rowData
        refreshData()
        addGlobalMessage('Selected rows successfully deleted', {
          severity: 'success',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        addGlobalMessage(
          errorMessages(err) || 'Error deleting selected rows'
        );
      });
  };

  const CustomGridToolbarContainer = () => {
    return (
      <GridToolbarContainer className={classes.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          slotProps={{
            button: {
              className: classes.exportButton,
            },
          }} />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteForeverOutlinedIcon />}
          onClick={onDelete}
          disabled={selectedRows.length === 0}
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
      </GridToolbarContainer>
    );
  };

  // const contentStyle = {
  //   height: '100%',
  //   width: '100%',
  // };

  // Update transaction or create if no ID provided (revert if updating AWAITING_SIGNATURE)
  const saveContribution = async (values: any): Promise<void> => {
    // contribution information saved in reducer
    const { sequenceNumber, irsTaxFileUploadId } = values;
    const id = irsTaxFileUploadId;

    const flattenedData = {
      sequenceNumber,
      accountId: values.grouping.oldAccountId,
      newAccountId:
        values.accountId === values.grouping.oldAccountId
          ? null
          : values.accountId,
      distributionReason: values.distributionReason,
      overrideContributionLimits: values.overrideContributionLimits,
      correctionData: values.grouping.details.map((item) => {
        return {
          detailId: item.detailId,
          field: item.field,
          reconciledAmount: item.deleted ? null : item.reconcileAmount,
          accountId: values.accountId,
          deleted: item.deleted,
        };
      }),
    };

    await taxReportingCorrectionRequest(
      flattenedData,
      user.organizationId,
      user.token,
      id
    )
      .then(() => {
        addGlobalMessage(`${values.accountOwnerName} successfully corrected`, {
          severity: 'success',
        });
        handleClose();
        onComplete(id);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || `Error Correcting ${values.accountOwnerName}`
        );
      });
  };

  const rowCountRef = useRef(totalRowCount || 0);

  const rowCount = useMemo(() => {
    if (totalRowCount !== undefined) {
      rowCountRef.current = totalRowCount;
    }
    return rowCountRef.current;
  }, [totalRowCount]);

  const retrieveData = async (row) => {
    const { id, irsTaxFileUploadId, sequenceNumber } = row;

    await getCorrectionData(
      user.organizationId,
      user.token,
      irsTaxFileUploadId,
      sequenceNumber
    )
      .then((res) => {
        const { errorType, errorDescription, description } = row;

        const errorTypeVal =
          errorType?.charAt(0).toUpperCase() + errorType?.slice(1);
        const errorDescriptionVal =
          errorDescription?.charAt(0).toUpperCase() +
          errorDescription?.slice(1);

        // find the selected row id and add the grouping data to the selected row
        const selectectedData = res.data.find((item) => item.id === id);

        // add detail ids res
        const details = res.data.map((item) => {
          return {
            ...item,
            detailId: item.id,
          };
        });

        const newData = { ...selectectedData, grouping: { details } };
        setSelectedRowData(newData);

        preselectAccountAndSetInfo(row);
        setErrorMessage(errorTypeVal || '');
        setErrorDetails(errorDescriptionVal || '');
        setDescription(description || '');

        setCorrectionSelectedAccountOwner({
          fullName: row.accountOwnerName,
          accountOwnerId: row.accountOwnerId,
        } as AccountOwner);
      })
      .catch((err) => {
        addGlobalMessage(
          errorMessages(err) || 'Could not fetch the correction data'
        );
      });
  };

  // useEffect(() => {
  //   console.log('filterOptions.matching', filterOptions.matching)
  //   setRowData([]);
  //   setIsLoading(true);
  // },[filterOptions.matching])


  return (
    <Box>
      <DataGridPro
        className={isPending ? classes.blurred : ''}
        apiRef={apiRef}
        slots={{
          noRowsOverlay: SiraNoRowsOverlay,
          toolbar: CustomGridToolbarContainer,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: globalPageSize } },
          pinnedColumns: { left: ['__check__', 'matching', 'accountOwnerName'] },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pagination
        pageSizeOptions={globalPaginationOptions}
        onPaginationModelChange={setGlobalPageSize}
        checkboxSelection={!filterOptions.matching}
        disableRowSelectionOnClick
        keepNonExistentRowsSelected
        columns={columns}
        rows={rowData}
        rowCount={rowCount}
        paginationMode="server"
        paginationMeta={paginationMeta}
        onSortModelChange={(model) => {
          handleSortChange(model);
        }}
        onRowSelectionModelChange={(rowSelectionModel, details) => {
          const selectedRowsData = details.api.getSelectedRows();
          handleSelectionChange(selectedRowsData);
        }}
        onFilterModelChange={debouncedFilter.current}
      />

      {/* Resizeable modal and ability to dtag  */}
      <SiraDrawer anchor="left" open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Correct {correctionName} {errorMessage}
              </Typography>
            </Grid>
            {errorDetails ? <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="error">
                {errorDetails}
              </Typography>
            </Grid> : null}
            {description ? <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="error">
                {description}
              </Typography>
            </Grid> : null}
            <Grid item xs={12}>
              <ContributionInfoCorrectionForm
                account={selectedAccount}
                initialValues={{
                  ...CONTRIBUTION_INFO_CORRECTION_INIT,
                  ...selectedRowData,
                }}
                onSubmit={async (data) => {
                  await saveContribution(data);
                }}
                onCancel={handleClose}
                explodeSteps
                errorDetails={errorDetails}
                accountOwner={selectedAccountOwner}
                batchType={batchType}
              />
            </Grid>
          </Grid>
        </Box>
      </SiraDrawer>
    </Box>
  );
}

function BatchNoOrg() {
  return (
    <Box p={2} pt={5} pb={5}>
      <Alert severity="warning">
        Select an organization to see batch import status
      </Alert>
    </Box>
  );
}

function BatchNoResults() {
  return (
    <Box width="1" textAlign="center" p={3}>
      <Typography variant="body2">No batch import files found</Typography>
    </Box>
  );
}

function TaxReportingInfoList(props: BatchInfoListProps) {
  const {
    batchData = [],
    isLoading = false,
    onComplete = () => { },
    batchType,
    fetchIrsTaxFile,
    batchFileData = [],
    totalRowCount = 0,
    hasNextPage = false,
    handleSortChange = () => { },
    handleFilterChange = () => { },
    filterOptions = {},
  } = props;
  const { user } = useUser();
  const [open, setOpen] = useState(false);


  if (!user.organizationId && !isLoading) {
    return <BatchNoOrg />;
  }

  const [selectedTaxFileUploadId, setSelectedTaxFileUploadId] = useState(
    '' as string
  );

  if (batchData.length === 0 && !isLoading) {
    return <BatchNoResults />;
  }

  return (
    <>
      <Grid container spacing={2} >
        {batchData.map((batchInfo) => {
          return (
            <Grid
              item
              xs={6}
              md={4}
              key={`batch_${batchInfo.irsTaxFileUploadId}`}
            >
              <TaxReportingFileCards
                irsTaxFileUploadId={batchInfo.irsTaxFileUploadId}
                fileName={batchInfo.fileName}
                createdByName={batchInfo.createdByName}
                created={batchInfo.created}
                selecteTaxFileUploadId={selectedTaxFileUploadId}
                setSelectedTaxFileUploadId={(value) => {
                  setSelectedTaxFileUploadId(value);
                  fetchIrsTaxFile(value);
                  setOpen(true);
                }}
              />
            </Grid>
          );
        })}
        {isLoading ? (
          <Box width="1" textAlign="center" p={3}>
            <LinearProgress color="secondary" />
          </Box>
        ) : (
          open && (
            <Grid item xs={12}>
              <BatchInfoCard
                onComplete={onComplete}
                batchInfo={batchFileData}
                batchType={batchType}
                totalRowCount={totalRowCount}
                hasNextPage={hasNextPage}
                handleSortChange={handleSortChange}
                handleFilterChange={handleFilterChange}
                filterOptions={filterOptions}
                refreshData={() => fetchIrsTaxFile(selectedTaxFileUploadId)}
              />
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}

export default TaxReportingInfoList;
